<template>
  <b-card>
    <b-overlay
      :show="!leads"
      rounded="sm"
    >
      <div class="pending-leads-table">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="leads"
          :columns="columns"
          :rows="leads"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Demo Version -->
            <span v-if="props.column.field === 'demo_name'">
              <b-badge :variant="demoVersion(props.row.demo_name)">
                {{ props.row.demo_name }}
              </b-badge>
            </span>

            <!-- Column: Website -->
            <span v-else-if="props.column.field === 'website'">
              <p class="truncate"><a
                v-b-tooltip.hover.focus.v-primary
                :href="props.row.website"
                target="_blank"
                :title="props.row.website"
              >{{ props.row.website }}</a></p>
            </span>

            <!-- Column: Instant Messenger -->
            <a
              v-else-if="props.column.field === 'messaging_tool'"
              class="m-0"
              target="_blank"
              :href="instantMessage(props.row.messaging_tool, props.row.contact)"
            >
              <i
                v-if="props.row.messaging_tool === 'Skype'"
                v-b-tooltip.hover.focus.v-primary
                title="Skype"
                class="bi bi-skype"
              />
              <i
                v-else-if="props.row.messaging_tool === 'Whatsapp'"
                v-b-tooltip.hover.focus.v-primary
                title="Whatsapp"
                class="bi bi-whatsapp"
              />
              <i
                v-else-if="props.row.messaging_tool === 'Telegram'"
                v-b-tooltip.hover.focus.v-primary
                title="Telegram"
                class="bi bi-telegram"
              />
            </a>

            <!-- Column: Presentation -->
            <span v-else-if="props.column.field === 'with_platform_presentation'">
              <feather-icon
                :icon="props.row.has_platform_presentation ? 'CheckIcon' : 'XIcon'"
                class="inline-flex"
                :class="props.row.has_platform_presentation ? 'text-success' : 'text-danger'"
                size="21"
              />
            </span>

            <!-- Column: Notes -->
            <span v-else-if="props.column.field === 'notes'">
              <router-link
                v-b-tooltip.hover.focus.v-primary
                :to="'/leads/' + props.row.id + '/add-note'"
                :class="props.row.notes ? 'bi bi-sticky-fill' : 'bi bi-sticky'"
                :title="props.row.notes"
              />
            </span>

            <!-- Column: IP -->
            <p
              v-else-if="props.column.field === 'ip'"
              class="m-0"
            >
              <a
                :id="`ip-info-${props.index}`"
                href="javascript:void(0)"
              >
                <b-tooltip
                  :target="`ip-info-${props.index}`"
                >
                  IP: {{ props.row.ip }}<br>
                  CC: {{ props.row.cc }}<br>
                  CREATED AT: {{ props.row.created_format }}<br>
                  UPDATED AT: {{ props.row.updated_format }}
                </b-tooltip>
                <feather-icon
                  icon="SearchIcon"
                  class="inline-flex"
                  size="20"
                />
              </a>
            </p>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="rejectUser(props.row.id)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                      style="margin-bottom: 4px"
                    />
                    <span>Reject</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteLeadUser(props.row.id)">
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                      style="margin-bottom: 4px"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','150']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BBadge,
  BTooltip,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BBadge,
    BTooltip,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      pageLength: 50,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          sortable: false,
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Demo Version',
          field: 'demo_name',
          sortable: false,
        },
        {
          label: 'Company',
          field: 'company',
          sortable: false,
        },
        {
          label: 'Company Description',
          field: 'companyDescription',
          sortable: false,
        },
        {
          label: 'Email',
          field: 'email',
          sortable: false,
        },
        {
          label: 'Website',
          field: 'website',
          sortable: false,
        },
        {
          label: 'Instant Messenger',
          field: 'messaging_tool',
          sortable: false,
        },
        {
          label: 'IM Address',
          field: 'contact',
          sortable: false,
        },
        {
          label: 'Presentation',
          field: 'with_platform_presentation',
          sortable: false,
        },
        {
          label: 'Notes',
          field: 'notes',
          sortable: false,
        },
        {
          label: 'IP',
          field: 'ip',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    demoVersion() {
      const presentationColor = {
        /* eslint-disable key-spacing */
        Marketplace       : 'light-info',
        'Full Features'   : 'light-primary',
        Casino      : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return presentation => presentationColor[presentation]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    leads() {
      return this.$store.state.users.calledLeads
    },
  },
  created() {
    this.fetchCalledLeads()
  },
  methods: {
    instantMessage(message_tool, contact) {
      const text = 'Hello, this is Florin from TrafficManager.com , the affiliate tracking platform. I saw you have filled out the form for our tracking platform online demo and I wanted to check and see if I can help you with anything or you have any questions about the product.'
      if (message_tool === 'Whatsapp') {
        return `https://api.whatsapp.com/send/?phone=${contact}&text=${text}&type=phone_number&app_absent=0`
      }
      if (message_tool === 'Telegram') {
        // remove from contact the @
        const contact_fix = contact.replace('@', '')
        contact_fix.replace('https://t.me/', '')
        return `https://telegram.me/${contact_fix}`
      }
      if (message_tool === 'Skype') {
        contact.replace('live:', '')
        return `skype:${contact}?chat`
      }
      return undefined
    },
    fetchCalledLeads() {
      this.$store.dispatch('users/fetchCalledLeads')
        .catch(error => { console.log(error) })
    },
    rejectUser(id) {
      this.confirmationAlert('Would you like to reject this user?')
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/updateLeadStatus', { id, status: 0 })
              .then(response => {
                this.$store.commit('users/REMOVE_CALLED_LEADS', id)
                this.$store.commit('users/INSERT_REJECTED_LEADS', response)
                this.notify({
                  text: 'User has been moved in the rejected list!',
                  variant: 'success',
                })
              })
              .catch(error => { console.log(error) })
          }
        })
    },
    deleteLeadUser(id) {
      this.confirmationAlert('Would you like to delete this user?')
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/deleteLeadUser', id)
              .then(() => {
                this.fetchCalledLeads()
                this.$bvToast.toast('User deleted successfully', {
                  title: 'Success',
                  variant: 'success',
                  solid: true,
                })
                this.$store.commit('users/REMOVE_CALLED_LEADS', id)
                this.$store.commit('users/REMOVE_PENDING_LEADS', id)
              })
              .catch(error => { console.log(error) })
          }
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.pending-leads-table {
  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  .truncate {
    width: 275px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 15px;
    margin: auto;
  }

  .bi {
    font-size: 20px;

    &.bi-skype {
      color: #00aff0;
    }

    &.bi-whatsapp {
      color: #44eb62;
    }

    &.bi-telegram {
      color: #3490ee;
    }
  }
}
</style>
